<template>
  <div class="th-container">
    <el-input v-model="pin" clearable @keydown.stop="handleInput">
      <template #prefix>
        <el-icon v-if="isPinValid !== null" class="el-input__icon"
          ><Check v-if="isPinValid" /><Close v-else
        /></el-icon>
      </template>
    </el-input>
    <el-button class="border-th-primary-blue" @click="generatePin">
      {{ $t('pages.staff.form.pin.generate') }}
    </el-button>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import debounce from 'debounce'
import { mapState } from 'vuex'

export default {
  props: {
    formPin: {
      type: String,
      required: false,
      default: ''
    },
    updatePin: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      pin: '',
      isPinValid: null
    }
  },
  computed: {
    ...mapState({
      pinLength: (state) =>
        state.Config?.payload?.staff?.cashier_pin_gen_length || 4
    })
  },
  watch: {
    formPin: function (newPin, oldPin) {
      if (newPin !== oldPin) {
        this.pin = newPin
      }
    },
    pin: function (newPin, oldPin) {
      if (newPin !== oldPin) {
        // Here we are intending to update the form pin only when pin is null,
        // because all other cases are already covered
        !newPin && this.resetFormPin()
      }
    }
  },
  updated() {
    if (!this.pin) this.isPinValid = null
  },
  methods: {
    async generatePin() {
      this.isPinValid = null
      try {
        const pinLength = { pin_length: this.pinLength }
        const { data } = await th.staff().getPin(pinLength)
        this.pin = data[0].pin
        this.isPinValid = true
        this.updatePin(this.pin)
      } catch (err) {
        this.$logException(err)
        this.isPinValid = null
      }
    },
    validatePin: debounce(async function (providedPin) {
      const staffId = this.$route.params.id || undefined
      try {
        const { data } = await th
          .staff()
          .getPin({ provided_pin: providedPin, staff_id: staffId })
        if (data[0].pin) this.isPinValid = true
        this.updatePin(this.pin)
      } catch (err) {
        if (err.properties.name === 'PinNotUnique') {
          this.isPinValid = false
          this.updatePin('')
        } else {
          this.$logException(err)
        }
      }
    }, 500),
    handleInput(e) {
      this.isPinValid = null
      if (!e) return
      e.preventDefault()

      if (!e.key) return
      if (e.code === 'Space') return

      const pinInt = Number(this.pin)
      // handle deletion
      if ((e.key === 'Delete' || e.key === 'Backspace') && pinInt) {
        const backSpacedNumber = Math.floor(pinInt / 10)
        this.pin =
          backSpacedNumber > 0
            ? `${backSpacedNumber}`.padStart(this.pinLength, 0)
            : ''
        this.pin && this.validatePin(this.pin)
      }

      if (!Number.isFinite(Number(e.key))) return

      const num = Number(e.key)
      if (pinInt.toString().length < this.pinLength) {
        this.pin = `${pinInt}${num}`.padStart(this.pinLength, 0)
        Number(this.pin) > 0 && this.validatePin(this.pin)
      }
    },
    resetFormPin() {
      this.updatePin('')
    }
  }
}
</script>

<style scoped>
.th-container {
  display: flex;
  width: 260px;
}

.el-input {
  width: 55%;
}

.th-container :deep(.el-input input) {
  border-radius: 4px 0 0 4px;
  direction: RTL;
}

.el-button {
  display: flex;
  justify-content: center;
  width: 45%;
  color: rgb(58, 161, 246);
  border-radius: 0 4px 4px 0;
}

.el-input__icon {
  font-size: 18px;
}

/* TODO CHECK */
.el-icon-close {
  color: #d0021b;
}

.el-icon-check {
  color: #67c23a;
}
</style>
